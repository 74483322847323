import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JsonDisplay from './Display';
import { Box, Button } from '@mui/material';

const LotteryFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false)
  useEffect(() => {
    const baseUrl = window.location.origin;
    // const download_url = "https://www.pais.co.il/Lotto/lotto_resultsDownload.aspx"
    // const proxy = "https://corsproxy.io/"
    // const url = 'https://corsproxy.io/?' + encodeURIComponent(`${download_url}`);
    // const url = 'https://corsproxy.io/' + "https://www.pais.co.il/Lotto/lotto_resultsDownload.aspx";
    const apiUrl = process.env.NODE_ENV === 'production' ? `${baseUrl}/api/lottery` : "http://127.0.0.1:8080/api/lottery";
    // const response = axios.get(url);
    // console.log(response.data)
    console.log(apiUrl)
    axios.get(apiUrl)
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [reload]);




  return (
    <Box>
      <JsonDisplay data={data} />

      <Button onClick={() => setReload(prev => !prev)}variant='contained'>Click to refresh</Button>
    </Box>
  );
};

export default LotteryFetch;
