import { List, ListItem, ListItemText, Typography, Box } from '@mui/material';

const PairList = ({ pairs, title }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom color="darkblue"
      sx={{
        fontSize: {
          xs: '0.875rem', // Small font size for extra-small screens
          sm: '1rem', // Default font size for small screens
          md: '1.25rem', // Medium font size for medium screens
          lg: '1.5rem', // Large font size for large screens
        },
        fontWeight: 'bold'
      }}
      >
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="nowrap">
        <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap' }}>
          {pairs.map((pair, index) => (
            <ListItem key={index}>
              <ListItemText primary={pair}
                primaryTypographyProps={{
                  sx: {
                    fontSize: {
                      xs: '0.7rem', // Smaller font size for extra-small screens
                      sm: '0.875rem', // Default font size for small screens
                      md: '1rem', // Medium font size for medium screens
                      lg: '1.125rem', // Large font size for large screens
                    },
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default PairList