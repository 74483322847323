import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import PairLists from './PairLists';

const JsonDisplay = ({ data }) => {
  if (!data) {
    return <p>...טוען</p>;
  }

  const joinedString = data?.latest.join(', ');
  return (
    <>
      <Box sx={{ p: 2, mx: 10, borderRadius: 1, boxShadow: 3 }}>
        <Typography variant="h6" gutterBottom>
          תאריך
        </Typography>
        {data.date}        
        <Typography>
        <br />
          <Typography variant="h6" gutterBottom>
            מספרים שהוגרלו:
          </Typography>
          {joinedString}
        </Typography>
        <Box my={4}>
          <Typography variant="h6" gutterBottom>
            מספרים מומלצים
          </Typography>
          {data.numbers.join(', ')}
        </Box>
      </Box>
      <PairLists data={data} />
    </>
  );

};

export default JsonDisplay;
