import React from 'react';
import { Container, Box, Grid, Paper, Button } from '@mui/material';
import PairList from './PairList'


const PairLists = ({ data }) => {
  const pairs1 = data.pair
  const pairs2 = data.triplet
  const pairs3 = data.quadruplet
  const pairs4 = data.quintuplet


  return (
    <Container>
      <Paper
        elevation={3}
        sx={{
          position: 'relative',
          padding: '20px',
          // height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '10px'
        }}
      >
        <Box display="flex" justifyContent="center">
          <Grid container spacing={4}>
            <Grid item xs={3} >
              <PairList pairs={pairs1} title="סבירות ל 2" />
            </Grid>
            <Grid item xs={3} >
              <PairList pairs={pairs2} title="סבירות ל 3" />
            </Grid>
            <Grid item xs={3}>
              <PairList pairs={pairs3} title="סבירות ל 4" />
            </Grid>
            <Grid item xs={3}>
              <PairList pairs={pairs4} title="סבירות ל 5" />
            </Grid>
          </Grid>
        </Box>
       
      </ Paper>
    </Container>
  );
};

export default PairLists;
